// @flow

import React from "react";
import {
    withPrivateRoute,
    Layout,
    AdminUserManagementLayout,
    Clusters,
} from "@containers";

/**
 * Admin Gebruikers Beheer - Interne Groepen
 */
const AdminInternalGroups = (props: *) => {
    return (
        <Layout {...props}>
            <AdminUserManagementLayout>
                <Clusters id="clusters" />
            </AdminUserManagementLayout>
        </Layout>
    );
};

export default withPrivateRoute(AdminInternalGroups);
